import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/index.vue'),
      meta: {
        keepAlive: false // 需要被缓存
      }
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
      meta: {
        keepAlive: true // 需要被缓存
      }
  },
  {
    path: '/djdetail',
    name: 'Djdetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Djdetail.vue'),
      meta: {
        keepAlive: false // 需要被缓存
      }
  },
  {
    path: '/receipt',
    name: 'Receipt',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Receipt.vue'),
      meta: {
        keepAlive: true // 需要被缓存
      }
  },
  {
    path: '/fkddetail',
    name: 'FkdDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/FkdDetail.vue'),
      meta: {
        keepAlive: true // 需要被缓存
      }
  },
  {
    path: '/receiptlist',
    name: 'ReceiptList',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ReceiptList.vue'),
      meta: {
        keepAlive: false // 需要被缓存
      }
  },
  {
    path: '/receiptdetail',
    name: 'ReceiptDetail',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ReceiptDetail.vue'),
      meta: {
        keepAlive: true // 需要被缓存
      }
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
